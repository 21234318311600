// @flow
import React from 'react';
import { Alert } from '@rea-group/construct-kit-core';
import adaptData from './adaptData';
import styled from 'styled-components';
import type { GetBuyResidentialListing } from '../../../models/lexa/index';

type Props = {
    data: GetBuyResidentialListing,
};

const StyledAlert = styled(Alert)`
    margin-bottom: 1.5rem;
`;

const IntroductionMessage = ({ data }: Props) => {
    const { message } = adaptData(data);
    return message ? <StyledAlert>{message}</StyledAlert> : null;
};

export default IntroductionMessage;
