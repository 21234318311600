/* @flow */
import adaptLexaData from './adaptLexaData';
import type { Node } from 'react';
import type { GetResidentialListing } from '../../models/lexa';

type Props = {
    data: GetResidentialListing,
    yes: ?Node,
    no: ?Node,
};

const IsBuilderListing = ({ data, yes, no }: Props) => {
    const { IsBuilderListing } = adaptLexaData(data);
    return (IsBuilderListing ? yes : no) || null;
};
export default IsBuilderListing;
