import type { BuyResidentialListing } from '../../../types';
import type { OnlineAuctionIndicator } from './types';

const minToMilliSec = (min: number) => min * 60000;

const isLiveAuction = (time: string) => {
    const timeDiff = Date.now() - Date.parse(time);
    return timeDiff >= -minToMilliSec(5) && timeDiff <= minToMilliSec(30);
};

const isTodayAuction = (time: string) => new Date(time).toDateString() === new Date().toDateString();

export const adaptOnlineAuctionIndicator = ({ auction }: BuyResidentialListing): OnlineAuctionIndicator => ({
    hasOnlineAuction: auction ? auction.onlineLinks.length > 0 : false,
    isLive: auction ? isLiveAuction(auction.dateTime.value) : false,
    isToday: auction ? isTodayAuction(auction.dateTime.value) : false,
});
