/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetRecentSales, RecentSales, GetSoldResidentialListing } from '../../models/lexa';
import RecentSalesData from './RecentSalesData.graphql';

type AdaptedData = {|
    title: string,
    items: Array<GetSoldResidentialListing>,
|};

const dataAdapter = (data: ?RecentSales): ?AdaptedData => {
    if (!data) return null;
    if (!data.items.length) return null;
    return {
        title: data.title,
        items: data.items.map((l) => ({ getListing: () => l })),
    };
};

export default compose<GetRecentSales, ?RecentSales, ?RecentSales, ?AdaptedData>(
    dataAdapter,
    getFragmentData(RecentSalesData),
    (data) => data.getRecentSales()
);
