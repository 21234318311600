/* @flow */
import adaptLexaData from './adaptLexaData';
import { View } from './View';
import React from 'react';
import type { GetBuyResidentialListing, GetEventTracker } from '../../../models/lexa';

type Props = {
    data: GetBuyResidentialListing & GetEventTracker,
};
const OnlineOffers = ({ data }: Props) => {
    const showOnlineOffersData = adaptLexaData(data);
    return showOnlineOffersData.onlineOffersUrl ? <View {...showOnlineOffersData} data={data} /> : null;
};

export default OnlineOffers;
