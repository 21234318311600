// @flow

import getListingByIdQuery from './getListingById.graphql';
import { type DetailsData } from '../details';
import getListingAboveTheFoldByIdQuery from './getListingAboveTheFoldById.graphql';
import { createFetchDetailsData, type FetchDetailsDataArgs, type FetchDetailsFullArgs } from '../details/fetchData';
import { withReissueToken } from '../../util/withReissueToken';
import { isTokenExpired } from './isTokenExpired';

const { fetchDetailsData: fetchDetailsDataOriginal, fetchDetailsFull: fetchDetailsFullOriginal } =
    createFetchDetailsData({
        getListingAboveTheFoldByIdQuery,
        getListingByIdQuery,
        channelQueryVariables: {
            testId: 'BuyDetails',
            queryCacheKey: 'BuyResidentialListingDetails',
        },
        fetchOptions: {
            credentials: 'include',
        },
    });

export const fetchDetailsData = withReissueToken<FetchDetailsDataArgs, DetailsData | void>(
    fetchDetailsDataOriginal,
    isTokenExpired
);

export const fetchDetailsFull = withReissueToken<FetchDetailsFullArgs, DetailsData | void>(
    fetchDetailsFullOriginal,
    isTokenExpired
);
