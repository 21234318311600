/* @flow */
import React from 'react';
import { HomeDesignCarousel } from '../../../components/details/footer-carousel';
import './styles.scss';
import type { ViewProps } from './types';

export default (props: ViewProps) => {
    const { name, description, backgroundColor, logo, homeDesigns, href, data } = props;
    const styles = { backgroundColor };
    if (homeDesigns) {
        return (
            <div className="builder-profile-footer">
                <div className="builder-profile-footer__branding" style={styles}>
                    <img src={logo} className="builder-profile-footer__logo" />
                </div>
                <div className="builder-profile-footer__content-wrapper">
                    <div className="builder-profile-footer__content">
                        <div className="builder-profile-footer__name">{name}</div>
                        <div
                            dangerouslySetInnerHTML={{ __html: description }}
                            className="builder-profile-footer__description"
                        />
                        <a href={href} className="builder-profile-footer__view-more">
                            View more home designs
                        </a>
                    </div>
                </div>
                <HomeDesignCarousel data={data} />
            </div>
        );
    }
    return null;
};
