/* @flow */

import React from 'react';
import type { GetBuyResidentialListing } from '../../../models/lexa/index';
import ListingTypeSwitch from '../ListingTypeSwitch';
import {
    BuilderTrafficDriver,
    ProjectTrafficDriver,
    AgencyTrafficDriver,
} from '../../../components/details/enquiry-traffic-driver-main-image';

export type Props = {|
    data: GetBuyResidentialListing,
|};

const EnquiryTrafficDriverMainImage = (props: Props) => {
    const { data } = props;
    return (
        <ListingTypeSwitch
            data={data}
            renderAgencyListing={() => <AgencyTrafficDriver data={data} />}
            renderBuilderListing={() => <BuilderTrafficDriver data={data} />}
            renderChildListing={() => <ProjectTrafficDriver data={data} />}
        />
    );
};

export default EnquiryTrafficDriverMainImage;
