/* @flow */
import { compose } from '../../../util/functional/compose';
import type { GetBuyResidentialListing, BuyResidentialListing, Faq } from '../../../models/lexa';
import getFragmentData from '../../../util/getFragmentData';
import FaqFragment from './Faq.graphql';

type Props = {
    faq: ?Faq,
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, Props>(getFragmentData(FaqFragment), (data) =>
    data.getListing()
);
