/* @flow */
import React from 'react';
import styled from 'styled-components';
import ErrorBoundaryNewRelicReporter from '../../../../components/error-boundary-new-relic-reporter';
import BrowserOnly from '../../../../components/browser-only';
import { isCalculatorDisplayedInline } from '../isCalculatorDisplayedInline';
import { Skeleton } from '@rea-group/construct-kit-core';

const LazyFinxPdpCalculator = React.lazy(
    () => import(/* webpackChunkName: "home-loan-calculator-uplift" */ './FinxLoanCalculatorLazy')
);
const LazyFinxPdpCalculatorModal = React.lazy(() =>
    import(/* webpackChunkName: "home-loan-calculator-uplift" */ './FinxLoanCalculatorLazy').then((module) => ({
        default: module.CalculatorModal,
    }))
);
const LazyFinxPdpContactModal = React.lazy(() =>
    import(/* webpackChunkName: "home-loan-calculator-uplift" */ './FinxLoanCalculatorLazy').then((module) => ({
        default: module.ContactModal,
    }))
);

const Placeholder = styled(Skeleton).attrs({ height: '46rem', borderRadius: 'medium' })`
    margin-top: 3rem;
`;

const isLargeScreen = isCalculatorDisplayedInline();

const NewCalculator = () => (
    <BrowserOnly name="FinxCalculator" fallback={<Placeholder />}>
        {isLargeScreen ? (
            <LazyFinxPdpCalculator />
        ) : (
            <React.Fragment>
                <LazyFinxPdpCalculatorModal />
                <LazyFinxPdpContactModal />
            </React.Fragment>
        )}
    </BrowserOnly>
);

const Calculator = () => {
    return (
        <ErrorBoundaryNewRelicReporter component="FinxCalculator">
            <NewCalculator />
        </ErrorBoundaryNewRelicReporter>
    );
};

export default Calculator;
