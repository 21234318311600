/* @flow */
import React, { type Node } from 'react';
import './styles.scss';

type Props = {|
    imageUrl: string,
    address: string,
    url: string,
    generalFeatures: ?Node,
    price: ?Node,
    dateSold: ?string,
|};

const RecentSale = ({ url, imageUrl, generalFeatures, address, price, dateSold }: Props) => {
    return (
        <article className="recent-sale">
            <img className="recent-sale__image" src={imageUrl} alt={address} />
            <div className="recent-sale__info">
                <p className="recent-sale__price">{price}</p>
                {generalFeatures}
                <a href={url} className="recent-sale__address">
                    {address}
                </a>
                {dateSold && (
                    <p aria-label={`Sold on ${dateSold}`} className="recent-sale__date">
                        {dateSold}
                    </p>
                )}
            </div>
        </article>
    );
};

export default RecentSale;
