/* @flow */

import getFragmentData from '../../../../util/getFragmentData';
import { compose3 as compose } from '../../../../util/functional/compose';
import type { Option } from '../../../../util/functional/Option';
import { none, some } from '../../../../util/functional/Option';
import type {
    GetBuyResidentialListing,
    GetPersonalisedAuction,
    GetEventTracker,
    Address,
    PersonalisedAuction,
    PersonalisedInspection,
} from '../../../../models/lexa';
import PersonalisedAuctionFragment from './PersonalisedAuction.graphql';
import { adaptOnlineAuctionIndicator } from '../../../auction-date';
import { type User } from '@realestate.com.au/user';
import { type Channel as ChannelType } from '../../../../models/Channel';
import { type AddToPlanUserAction, type AddToPlanEnabledFeatureType } from '../../../../client/tracking/event/types';

export type SaveToCalendarData = {
    address: Address,
    event: PersonalisedAuction | PersonalisedInspection,
    auctionUrl?: ?string,
    onlineAuctionDescription?: ?string,
    onlineAuctionCtaDisplay?: ?string,
    listingId: string,
    templatedUrl?: ?string,
    getUser?: () => User,
};

type TrackingProps = {
    eventTrackers: (inPageSource?: string) => {
        trackSaveToCalendar: () => void,
        trackSaveToCalendarSuccess: () => void,
    },
    trackAddToPlan: (enabledFeatureType?: AddToPlanEnabledFeatureType) => (userAction: AddToPlanUserAction) => void,
    registrationEventTrackers: {
        inspectionRegistrationFormOpen: () => void,
        inspectionRegistrationFormSubmitted: () => void,
    },
};

const constructAuctionUrl = (auction) => auction?.onlineLinks[0]?.href;

const getOnlineAuctionDescription = (isLive, isToday) => {
    if (isLive) {
        return `This property's auction is now live.`;
    }
    if (isToday) {
        return 'This property is being auctioned today';
    }
    return 'An Online Auction is available for this property';
};

const getOnlineAuctionCta = (isLive) => (isLive ? 'Watch online auction' : 'View online auction');

const adaptLexaData = (details): Option<SaveToCalendarData> => {
    const { auction, listing } = details;
    const { address, id: listingId, media } = listing;
    return auction
        ? some(auction).map(() => {
              const { isLive, isToday } = adaptOnlineAuctionIndicator(listing);
              const auctionUrl = constructAuctionUrl(auction);
              return {
                  auctionUrl,
                  event: auction,
                  listingId,
                  templatedUrl: media.mainImage.templatedUrl,
                  address,
                  onlineAuctionDescription: auctionUrl ? getOnlineAuctionDescription(isLive, isToday) : null,
                  onlineAuctionCtaDisplay: auctionUrl ? getOnlineAuctionCta(isLive) : null,
              };
          })
        : none;
};

const getAuctionData = compose(
    adaptLexaData,
    getFragmentData(PersonalisedAuctionFragment),
    (data: GetBuyResidentialListing & GetPersonalisedAuction) => {
        return { auction: data.getAuction(), listing: data.getListing() };
    }
);

export type SaveToCalendarProps = SaveToCalendarData & TrackingProps;

export default (channel: ChannelType) =>
    (data: GetBuyResidentialListing & GetEventTracker & GetPersonalisedAuction): ?SaveToCalendarProps => {
        const maybeAuctionData = getAuctionData(data);
        const saveToCalendarTracker = data.getEventTracker().saveToCalendar('auction', 'auction_section');
        const saveToCalendarSuccessTracker = data.getEventTracker().saveToCalendarSuccess('auction', 'auction_section');

        return maybeAuctionData.fold(
            () => null,
            (auctionData: SaveToCalendarData) => {
                const trackingData = {
                    startTime: ((auctionData.event: any): PersonalisedAuction).dateTime.value,
                    status: channel === 'sold' ? 'sold' : 'active',
                    channel: channel,
                };
                return {
                    ...auctionData,
                    eventTrackers: () => ({
                        trackSaveToCalendar: saveToCalendarTracker,
                        trackSaveToCalendarSuccess: saveToCalendarSuccessTracker,
                    }),
                    trackAddToPlan: data.getEventTracker().addToPlan({ ...trackingData, itemType: 'auction' }),
                    registrationEventTrackers: {
                        inspectionRegistrationFormOpen: data
                            .getEventTracker()
                            .inspectionRegistrationFormOpen(trackingData),
                        inspectionRegistrationFormSubmitted: data
                            .getEventTracker()
                            .inspectionRegistrationFormSubmitted(trackingData),
                    },
                };
            }
        );
    };
