/* @flow */
import React from 'react';
import View from './View';
import adaptData from './adaptData';
import { ResidentialListingGeneralFeatures as GeneralFeatures } from '../../general-features';
import type { GetSoldResidentialListing } from '../../../models/lexa';
import Price from '../../price';

type Props = {
    data: GetSoldResidentialListing,
};

const Item = ({ data }: Props) => {
    const adaptedData = adaptData(data);
    return <View {...adaptedData} generalFeatures={<GeneralFeatures data={data} />} price={<Price data={data} />} />;
};

export default Item;
