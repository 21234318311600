// @flow
import { type BuyResidentialListingDetails } from '../../models/lexa';
import { type DetailsData } from '../details';

export const isTokenExpired = (detailsData?: DetailsData) => {
    if (detailsData && detailsData.value.details.__typename === 'BuyResidentialListingDetails') {
        const buyDetails: BuyResidentialListingDetails = detailsData.value.details;
        return buyDetails?.consumerContext?.loggedInStatus === 'LOGGED_IN_EXPIRED';
    }
    return false;
};
