/* @flow */
import adaptLexaData from './adaptLexaData';
import type { Node } from 'react';
import type { GetBuyResidentialListing } from '../../models/lexa';

type Props = {
    data: GetBuyResidentialListing,
    yes: ?Node,
    no: ?Node,
};

const IsChildListing = ({ data, yes, no }: Props) => {
    const { isChildListing } = adaptLexaData(data);
    return (isChildListing ? yes : no) || null;
};
export default IsChildListing;
