/* @flow */
import React from 'react';
import View from './View';
import adaptData from './adaptData';
import type { GetRecentSales } from '../../models/lexa';

type DataType = {
    data: GetRecentSales,
};

export default ({ data }: DataType) => {
    const adaptedData = adaptData(data);
    if (adaptedData) {
        return <View {...adaptedData} />;
    }
    return null;
};
