/* @flow */

import React from 'react';
import type { BuyResidentialListingDetailsGetters } from '../../../models/lexa';
import ListingTypeSwitch from '../ListingTypeSwitch';
import {
    SidebarTrafficDriverForAgencyListing,
    SidebarTrafficDriverForBuilderListing,
    SidebarTrafficDriverForChildListing,
    type Cid,
} from '../../../components/details/sidebar-traffic-driver';

type Props = {
    data: BuyResidentialListingDetailsGetters,
    className?: string,
    cidFrom: Cid,
};

const SidebarTrafficDriver = (props: Props) => {
    const { data } = props;
    return (
        <ListingTypeSwitch
            data={data}
            renderAgencyListing={() => <SidebarTrafficDriverForAgencyListing {...props} />}
            renderBuilderListing={() => <SidebarTrafficDriverForBuilderListing {...props} />}
            renderChildListing={() => <SidebarTrafficDriverForChildListing {...props} />}
        />
    );
};

export default SidebarTrafficDriver;
