import React from 'react';
import { LoanCalculatorComponentsProvider } from '@rea-group/fs-loan-calculator-components';
import { isCalculatorDisplayedInline } from './isCalculatorDisplayedInline';
import adaptLexaData from './calculator/adaptLexaData';

const FinxComponentsProvider = ({ data, children }) => {
    const processedData = adaptLexaData(data);

    return (
        <LoanCalculatorComponentsProvider
            listingDetails={processedData.listingDetails}
            propertyDetails={processedData.address}
            isLargeScreen={isCalculatorDisplayedInline()}
        >
            {children}
        </LoanCalculatorComponentsProvider>
    );
};

export default FinxComponentsProvider;
