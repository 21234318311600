/* @flow */
import React, { useState } from 'react';
import { Button, Inline, HomeMoneyLg, paletteHelper, spacingHelper, Text } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import type { OnlineOffersViewProps } from './types';
import BrowserOnly from '../../browser-only';

const OnlineOffersContainer = styled.div`
    padding: ${spacingHelper('medium')};
    background-color: ${paletteHelper('backgroundPrimaryBase')};
    border: 1px solid ${paletteHelper('borderSecondary')};
    border-radius: ${spacingHelper('small')};
    margin-top: ${spacingHelper('large')};
`;

const IconWrapper = styled.div`
    border-radius: 50%;
    background-color: ${paletteHelper('backgroundError')};
    padding: ${spacingHelper('extraSmall')};
    height: ${spacingHelper('twoExtraLarge')};
    width: ${spacingHelper('twoExtraLarge')};
`;

const OnlineOffersModalLazy = React.lazy(() => {
    return import(/* webpackChunkName: "realtair-online-offers" */ './OnlineOffersModal');
});

const LazyOnlineOffersModal = ({ isOpen, onClose, onLinkClick, address, data }) => (
    <BrowserOnly name="RealtairOnlineOffersModal">
        <OnlineOffersModalLazy
            isOpen={isOpen}
            onClose={onClose}
            onLinkClick={onLinkClick}
            address={address}
            data={data}
        />
    </BrowserOnly>
);

export const View = ({
    address,
    onlineOffersUrl,
    onlineOffersModalOpenTracker,
    onlineOffersGetStartedClickTracker,
    data,
}: OnlineOffersViewProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleMakeAnOfferClick = () => {
        onlineOffersModalOpenTracker();
        setIsModalOpen(true);
    };
    const handleRealtairLinkClick = () => {
        window.open(onlineOffersUrl, '_blank');
        onlineOffersGetStartedClickTracker();
        setIsModalOpen(false);
    };
    return (
        <div>
            <OnlineOffersContainer className="property-info__online-offers">
                <Inline grow={false} gap="extraSmall">
                    <IconWrapper>
                        <HomeMoneyLg />
                    </IconWrapper>
                    <Text variant="subtitle01">Ready to take the next step?</Text>
                </Inline>
                <Button
                    variant="secondary"
                    sizeVariant="medium"
                    onClick={handleMakeAnOfferClick}
                    aria-label="Make an offer. Click to open a dialog"
                >
                    Make an offer
                </Button>
            </OnlineOffersContainer>
            <LazyOnlineOffersModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                onLinkClick={handleRealtairLinkClick}
                address={address}
                data={data}
            />
        </div>
    );
};
