/* @flow */
import type { BuyResidentialListing, GetBuyResidentialListing, GetRelatedProperties } from '../../../models/lexa';

const shouldShowHomeDesignFooter = (listingCompany) => {
    if (listingCompany && listingCompany.__typename === 'Builder') {
        const { homeDesigns } = listingCompany;
        return homeDesigns && homeDesigns.designs.length > 1;
    }
};

const shouldShowProjectFooter = (id, parent) => {
    if (parent && parent.childListings.results.length > 1) {
        const childListings = parent.childListings.results.filter((child) => child.id !== id);
        return childListings.length >= 1;
    }
};

const shouldShowRelatedPropertiesFooter = (relatedProperties) =>
    relatedProperties && relatedProperties.items.length >= 1;

const dataAdapter = (data: GetBuyResidentialListing & GetRelatedProperties) => {
    const { id, parent, listingCompany }: BuyResidentialListing = data.getListing();
    const showHomeDesignFooter = shouldShowHomeDesignFooter(listingCompany);
    const showProjectFooter = shouldShowProjectFooter(id, parent);
    const showRelatedPropertiesFooter = shouldShowRelatedPropertiesFooter(data.getRelatedProperties());

    return { showHomeDesignFooter, showProjectFooter, showRelatedPropertiesFooter };
};

export default dataAdapter;
