/* @flow */
import getFragmentData from '../../util/getFragmentData';
import { compose3 as compose } from '../../util/functional/compose';
import type { GetResidentialListing, ResidentialListing } from '../../models/lexa';
import { BUILDER } from '../../models/lexa/listingCompanyType';
import IsBuilderListing from './IsBuilderListing.graphql';

type AdaptedProps = {
    IsBuilderListing: boolean,
};

const adapter = (data) => {
    return { IsBuilderListing: data.listingCompany && data.listingCompany.__typename === BUILDER ? true : false };
};

export default compose<GetResidentialListing, ResidentialListing, ResidentialListing, AdaptedProps>(
    adapter,
    getFragmentData(IsBuilderListing),
    (data) => data.getListing()
);
