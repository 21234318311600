/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetBuyResidentialListing, BuyResidentialListing } from '../../../models/lexa';
import type { AdaptedData } from './types';
import ProjectFooter from './ProjectFooter.graphql';

const dataAdapter = ({ parent }: BuyResidentialListing): ?AdaptedData => {
    if (!parent) return null;
    const cid = 'pppdp|buy:pdp:footer-overview';
    const { childListings, name, title, description } = parent;
    const totalCountOfChildListings = childListings.totalCount;
    return {
        name,
        title,
        description,
        backgroundColor: parent.branding.primaryColour,
        projectLink: `${parent._links.canonical.path}?cid=${cid}`,
        numOfChildListings: totalCountOfChildListings > 1 ? totalCountOfChildListings : null,
        templatedUrl: parent.media.logo.templatedUrl,
    };
};

const getListingData = compose(dataAdapter, getFragmentData(ProjectFooter), (data: GetBuyResidentialListing) =>
    data.getListing()
);

export default (data: GetBuyResidentialListing) => {
    const listingData = getListingData(data);
    return listingData
        ? {
              ...listingData,
              data,
          }
        : null;
};
