// @flow
import React from 'react';
import View from './View';
import adaptData from './adaptData';
import type { GetBuyResidentialListing } from '../../../models/lexa';

type Props = {
    data: GetBuyResidentialListing,
};

const Faq = ({ data }: Props) => {
    const { faq } = adaptData(data);
    return faq ? <View faq={faq} /> : null;
};

export default Faq;
