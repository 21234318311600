/* @flow */
import React, { Fragment } from 'react';
import {
    ContactAgentBranding as ContactAgentBrandingForStandardListing,
    ContactAgentBrandingForChildListing,
} from '../../../components/details/contact-agent-branding';
import IsChildListing from '../../../components/is-child-listing';
import type { BuyResidentialListingDetailsGetters } from '../../../models/lexa';
import {
    ContactAgentFormContainer,
    PropertyContextWithInspection,
    ResidentialListingConfirmationModal,
    ResidentialListingContactAgentForm,
    ResidentialListingEnquiryTitle,
} from '../../../components/details/contact-agent';
import SingleOrDoubleColumn from '../../../components/SingleOrDoubleColumn';
import ProjectOrListingCompanyContext from '../../../components/details/contact-agent/project-or-listing-company-context';
import AgentInfo from '../../../components/details/agent-info';
import type { Element as ReactElement } from 'react';
import FormSelector from '../../../components/form-selector';
import { ResidentialMultiPageForm } from '../../../components/details/contact-agent/multi-page-form';

type Props = {
    data: BuyResidentialListingDetailsGetters,
    className: string,
    cid: string,
};

type FormDetailsProps = {
    data: BuyResidentialListingDetailsGetters,
    renderTrafficDriver: () => ReactElement<*>,
};

const ContactAgentBranding = (props: Props) => (
    <IsChildListing
        yes={<ContactAgentBrandingForChildListing {...props} />}
        no={<ContactAgentBrandingForStandardListing {...props} />}
        data={props.data}
    />
);

const AgentAndListingInfo = ({ data, renderTrafficDriver }: FormDetailsProps) => (
    <Fragment>
        <SingleOrDoubleColumn>
            {({ isSingleCol }) =>
                isSingleCol && (
                    <ProjectOrListingCompanyContext
                        data={data}
                        singleColOnly={true}
                        assumeSingleColumn={false}
                        renderTrafficDriver={renderTrafficDriver}
                    />
                )
            }
        </SingleOrDoubleColumn>
        <ResidentialListingEnquiryTitle data={data} />
        <AgentInfo
            data={data}
            isHorizontal={true}
            revealPageSource="contact form"
            cid="agent-profile-page|buy:pdp:contact-form"
        />
        <PropertyContextWithInspection data={data} />
    </Fragment>
);

const renderSinglePageForm = ({ data, renderTrafficDriver }: FormDetailsProps) => (
    <Fragment>
        <AgentAndListingInfo data={data} renderTrafficDriver={renderTrafficDriver} />
        <ContactAgentFormContainer
            data={data}
            renderConfirmationModal={(props) => {
                return <ResidentialListingConfirmationModal data={data} {...props} />;
            }}
            contactAgentForm={ResidentialListingContactAgentForm}
        />
    </Fragment>
);

const renderMultiPageForm = ({ data, renderTrafficDriver }: FormDetailsProps) => (
    <ContactAgentFormContainer
        data={data}
        renderConfirmationModal={(props) => <ResidentialListingConfirmationModal data={data} {...props} />}
        contactAgentForm={(props) => (
            <ResidentialMultiPageForm
                {...props}
                agentAndListingInfo={<AgentAndListingInfo data={data} renderTrafficDriver={renderTrafficDriver} />}
            />
        )}
    />
);
const ContactAgentForm = ({ data, renderTrafficDriver }: FormDetailsProps) => (
    <FormSelector
        data={data}
        singlePageForm={renderSinglePageForm({ data, renderTrafficDriver })}
        multiPageForm={renderMultiPageForm({ data, renderTrafficDriver })}
    />
);

export { ContactAgentBranding };
export default ContactAgentForm;
