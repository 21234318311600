/* @flow */
import type {
    Address,
    BuyResidentialListing,
    GetBuyResidentialListing,
    OnlineOffers,
    GetEventTracker,
} from '../../../models/lexa';
import getFragmentData from '../../../util/getFragmentData';
import OnlineOffersInfo from './OnlineOffersInfo.graphql';
import { compose3 as compose } from '../../../util/functional/compose';
import type { AdaptedOnlineOffersProps, AdaptedOnlineOffersFragment } from './types';

type Props = {
    address: Address,
    onlineOffers: ?OnlineOffers,
};

const dataAdapter = ({ address, onlineOffers }: Props): AdaptedOnlineOffersFragment => {
    return {
        address: address.display.fullAddress,
        onlineOffersUrl: onlineOffers?.realtair?.link,
    };
};

const getOnlineOffersData = compose<
    GetBuyResidentialListing,
    BuyResidentialListing,
    Props,
    AdaptedOnlineOffersFragment,
>(dataAdapter, getFragmentData(OnlineOffersInfo), (data) => data.getListing());

const getEventTrackerData = ({ getEventTracker }: GetEventTracker) => ({
    onlineOffersModalOpenTracker: () => getEventTracker().onlineOffersModalOpen(),
    onlineOffersGetStartedClickTracker: () => getEventTracker().onlineOffersGetStartedClick(),
});

export default (data: GetBuyResidentialListing & GetEventTracker): AdaptedOnlineOffersProps => ({
    ...getOnlineOffersData(data),
    ...getEventTrackerData(data),
});
