/* @flow */
import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetBuyResidentialListing } from '../../../models/lexa/getListing';

type DataType = {
    data: GetBuyResidentialListing,
};

export default ({ data }: DataType) => {
    const adaptedData = adaptLexaData(data);
    if (adaptedData) {
        return <View {...adaptedData} />;
    }
    return null;
};
