/* @flow */
import React from 'react';
import Auction from '../../../components/details/calendar-events/auction';
import Inspection from '../../../components/details/calendar-events/open-for-inspection';
import type { BuyResidentialListingDetailsGetters } from '../../../models/lexa';
import useScrollToElementWithHashUrl from '../../../components/details/useScrollToElementWithHashUrl';
import useScrollToElement from '../../../components/details/useScrollToElement';
import IsChildListing from '../../../components/is-child-listing';

type Props = {
    data: BuyResidentialListingDetailsGetters,
    className: string,
    contactAgentEl: () => ?Element,
    inspectionTimesEl: () => ?Element,
    setInspectionTimesEl: (?Element) => void,
};

const AutoScrolledAuctionAndInspection = ({
    data,
    className = '',
    contactAgentEl,
    inspectionTimesEl,
    setInspectionTimesEl,
}: Props) => {
    useScrollToElement(() => window.location.search.includes('scrollTo=inspection-times'), inspectionTimesEl);
    useScrollToElementWithHashUrl('#open-for-inspection', inspectionTimesEl);
    return (
        <div className={className} ref={setInspectionTimesEl}>
            <Auction data={data} contactAgentEl={contactAgentEl} />
            <Inspection data={data} contactAgentEl={contactAgentEl} id="inspections-list" />
        </div>
    );
};

const View = (props: Props) => {
    const { data, contactAgentEl, className } = props;
    return (
        <IsChildListing
            data={data}
            yes={
                <div className={className}>
                    <Auction data={data} contactAgentEl={contactAgentEl} />
                </div>
            }
            no={<AutoScrolledAuctionAndInspection {...props} />}
        />
    );
};

export default View;
