// @flow
import React, { Fragment } from 'react';
import { Stack, Accordion, AccordionItem, P, Ul, Li } from '@rea-group/construct-kit-core';
import SectionHeading from '../section-heading';
import type { Faq as FaqType, FaqAnswer } from '../../../models/lexa/faqType';

type Props = {
    faq: FaqType,
};

const Answer = ({ answer }: { answer: FaqAnswer }) => {
    return answer.map((block, i) => {
        if (block.__typename === 'FaqText') {
            return <P key={i}>{block.faqTextValue}</P>;
        }
        return (
            <Fragment key={i}>
                {block.faqListTextValue && <P>{block.faqListTextValue}</P>}
                <Ul>
                    {block.faqBulletPointListValue.map((item, j) => (
                        <Li key={j}>{item}</Li>
                    ))}
                </Ul>
            </Fragment>
        );
    });
};

const View = ({ faq }: Props) => {
    return (
        <Stack gap="small">
            <SectionHeading>{faq.title}</SectionHeading>
            <Accordion>
                {faq.list.map(({ question, answer }, index) => (
                    <AccordionItem key={index} id={`faq-item-${index}`} title={question}>
                        <Answer answer={answer} />
                    </AccordionItem>
                ))}
            </Accordion>
        </Stack>
    );
};

export default View;
