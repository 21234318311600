/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import createPicture from '../../../models/Picture';
import type { Builder, GetResidentialListing, ResidentialListing, ListingCompany } from '../../../models/lexa';
import type { AdaptedData } from './types';
import BuilderProfileFooter from './BuilderProfileFooter.graphql';

type RequiredProps = {
    listingCompany: ?ListingCompany,
};

const dataAdapter = ({ listingCompany }: RequiredProps): ?AdaptedData => {
    const builder = ((listingCompany: any): Builder);
    if (!!builder && !!builder.homeDesigns) {
        const { homeDesigns } = builder;
        const { totalCount } = homeDesigns;
        const usePluralForm = totalCount > 1;
        const numberOfHomeDesigns = usePluralForm ? `${totalCount} home designs` : 'home design';
        const cid = `nhbpp|buy:pdp:footer-overview`;

        return {
            name: builder.name,
            description: `Explore ${numberOfHomeDesigns} by ${builder.name}`,
            backgroundColor: builder.branding.primaryColour,
            logo: builder.media.logo ? createPicture(builder.media.logo.templatedUrl).ofSize('160x30') : '',
            homeDesigns: builder.homeDesigns,
            href: builder._links.canonical.href.replace('{cid}', cid),
        };
    }
    return null;
};

export default compose<GetResidentialListing, ResidentialListing, RequiredProps, ?AdaptedData>(
    dataAdapter,
    getFragmentData(BuilderProfileFooter),
    (data: GetResidentialListing) => data.getListing()
);
