/* @flow */
import React from 'react';
import View from './View';
import type { BuyResidentialListingCardGetters } from '../../../../models/lexa';
import adaptLexaData from './adaptLexaData';

type Props = {
    data: BuyResidentialListingCardGetters,
    showTooltip: boolean,
    allowFallbackPdf: boolean,
};

const IndicativePriceRange = ({ data, showTooltip, allowFallbackPdf }: Props) => {
    const information = adaptLexaData(data);

    const { disclaimerInteractionEvent, statementOfInformationLinkClick } = data.getEventTracker();

    return (
        <View
            information={information}
            showTooltip={showTooltip}
            allowFallbackPdf={allowFallbackPdf}
            disclaimerInteractionEvent={disclaimerInteractionEvent}
            statementOfInformationLinkClick={statementOfInformationLinkClick}
        />
    );
};

export default IndicativePriceRange;
