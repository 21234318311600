/* @flow */
import React, { type Node } from 'react';
import { ResidentialMap } from '../../../components/details/map';
import { ChildListingProjectDisplayMap } from '../../../components/details/project-display-map';
import IsChildListing from '../../../components/is-child-listing';
import type { BuyResidentialListingDetailsGetters } from '../../../models/lexa';

type Props = {
    data: BuyResidentialListingDetailsGetters,
    inspectionsEl: Node,
    contactAgentEl: () => ?Element,
    inspectionTimesEl: () => ?Element,
    setInspectionTimesEl: (?Element) => void,
};

export default ({ data, contactAgentEl, setInspectionTimesEl, inspectionTimesEl, inspectionsEl }: Props) => {
    return (
        <IsChildListing
            data={data}
            yes={
                <ChildListingProjectDisplayMap
                    inspectionTimesEl={inspectionTimesEl}
                    setInspectionTimesEl={setInspectionTimesEl}
                    data={data}
                    contactAgentEl={contactAgentEl}
                    inspectionsEl={inspectionsEl}
                />
            }
            no={<ResidentialMap data={data} />}
        />
    );
};
