/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import RecentSale from './RecentSale.graphql';
import type { GetSoldResidentialListing, SoldResidentialListing } from '../../../models/lexa';
import createPicture from '../../../models/Picture';
import { DEFAULT_DISPLAY_ADDRESS } from '../../../models/Address';

type AdaptedProps = {|
    imageUrl: string,
    address: string,
    url: string,
    dateSold: ?string,
|};

const dataAdapter = ({ address, _links, media, dateSold }: SoldResidentialListing): AdaptedProps => ({
    imageUrl: createPicture(media.mainImage.templatedUrl).ofSize('200x150'),
    address: address.display.fullAddress || DEFAULT_DISPLAY_ADDRESS,
    url: `${_links.canonical.path}?cid=pdp:recent-sales`,
    dateSold: dateSold ? dateSold.display : null,
});

export default compose<GetSoldResidentialListing, SoldResidentialListing, SoldResidentialListing, AdaptedProps>(
    dataAdapter,
    getFragmentData(RecentSale),
    (data) => data.getListing()
);
