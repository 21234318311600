/* @flow */
import React from 'react';
import adaptData from './adaptData';
import styled from 'styled-components';
import { Text, paletteHelper } from '@rea-group/construct-kit-core';
import type { GetBuyResidentialListing } from '../../../models/lexa/index';

type Props = {
    data: GetBuyResidentialListing,
};

const StyledP = styled(Text)`
    color: ${paletteHelper('textSecondary')};
`;

const PriceDisclaimer = ({ data }: Props) => {
    const { disclaimer } = adaptData(data);
    return disclaimer ? <StyledP variant="caption">{disclaimer}</StyledP> : null;
};

export default PriceDisclaimer;
