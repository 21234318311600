/* @flow */
import { compose3 as compose } from '../../../util/functional/compose';
import type { GetBuyResidentialListing, BuyResidentialListing, Introduction } from '../../../models/lexa';
import getFragmentData from '../../../util/getFragmentData';
import IntroductionMessage from './IntroductionMessage.graphql';

type Props = {
    introduction: ?Introduction,
};

type AdapterProps = {
    message: ?string,
};

const dataAdapter = ({ introduction }: Props) => {
    return { message: introduction?.message };
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, Props, AdapterProps>(
    dataAdapter,
    getFragmentData(IntroductionMessage),
    (data) => data.getListing()
);
