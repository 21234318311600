/* @flow */
import React, { Fragment } from 'react';
import { DetailsLayout } from '../../components/details/layout';
import PropertyFeatures from '../../components/details/property-features';
import ContextualPropertyInfo from '../../components/details/contextual-property-info';
import ScrollableGetInTouchButton from '../../components/details/scrollable-get-in-touch-button';
import PropertyInfo from '../../components/details/property-info';
import { ResidentialListingNavSecondary as NavSecondary } from '../../components/details/nav-secondary';
import { ChildListingDocDownload } from '../../components/details/doc-download';
import { ChildListingParentProjectBanner } from '../../components/project-banner';
import HeaderBranding, { HeaderBrandingForChildListing } from '../../components/details/header-branding';
import InspectionsSummary from '../../components/details/inspections-summary';
import IsChildListing from '../../components/is-child-listing';
import { InteractiveHero as Hero } from '../../components/hero';
import { ResidentialListingSchools as Schools } from '../../components/details/schools';
import { DetailsMarketInsights } from '../../components/market-insights';
import ListingMetrics from '../../components/details/listing-metrics';
import DividedContent from '../../components/divided-content';
import RenderBelowTheFold from '../../components/RenderBelowTheFold';
import { ConsumerRequestsProvider } from '../../components/details/contact-agent';
import { ContactAgentLayout, RenderAgencyBrandingIfRequired } from '../../components/details/contact-agent-v2';
import type { BuyResidentialListingDetailsGetters } from '../../models/lexa';
import RecentSales from '../../components/recent-sales';
import ContactAgentPanel from '../../components/details/contact-agent-panel';
import FloorplansTours from '../../components/details/floorplans-tours';
import HighlightVideo from '../../components/details/highlight-video';
import { MediaViewerForDetails } from '../../components/media-viewer';
import Description from '../../components/details/description';
import ProjectOrListingCompanyContext from '../../components/details/contact-agent/project-or-listing-company-context';
import { ActionPanel, MediaOverview } from '../../product-components';
import PersonalisedPropertyFeatures from '../../product-components/personalised-property-features';
import type { ResidentialListingDataType as CallAgentModalResidentialListing } from '../../components/details/call-agent-modal/types';
import {
    RenovationCalculator,
    FloorMedRecAdvert,
    WidgetAd,
    DetailsMedRec1Ad,
    DetailsMedRec2Ad,
    DetailsMedRec3Ad,
} from '../../components/advert';
import PageTracker from '../../components/details/tracking';
import PersonalisedData from '../../components/personalised-data';
import {
    createFetchListingBookmarks,
    createFetchEnquiryFormPrefillData,
} from '../../components/personalised-data/fetch-data';
import { createRemoveEnquiryFormPrefillData } from '../../components/personalised-data/remove-data';
import TravelTime from '../../components/details/travel-time';
import { ChildListingRequestProjectInfoKitPanel } from '../../components/details/request-project-info-kit-panel';
import type { RouterLocation } from '../../util/routerTypes';
import {
    ResidentialListingStatementOfInformation as StatementOfInformation,
    ResidentialListingDividedStatementOfInformation as DividedStatementOfInformation,
} from '../../components/details/statement-of-information';
import PriceDisclaimer from '../../components/details/price-disclaimer';
import SingleOrDoubleColumn from '../../components/SingleOrDoubleColumn';

import { ResidentialListingShareListing as ShareListing } from '../../components/share-listing';
import StatusBadge from '../../components/badge';
import AuctionDate from '../../components/details/auction-date';
import {
    ResidentialListingBookmarkStar as BookmarkStar,
    ResidentialListingBookmark as BookmarkButton,
} from '../../components/listing-bookmark';
import Faq from '../../components/details/faq';
import IntroductionMessage from '../../components/details/introduction-message';
import { Stack } from '@rea-group/construct-kit-core';
import { ContentLayoutPlaceholder, default as FullWidthPlaceholder } from '../details/loading-placeholder';
import ContactAgentForm, { ContactAgentBranding } from './contact-agent';
import EnquiryTrafficDriverMainImage from './enquiry-traffic-driver-main-image';
import SidebarTrafficDriver from './sidebar-traffic-driver';
import HomeLoanCalculatorLauncher from './home-loan/calculator-launcher';
import HomeLoanCalculator from './home-loan/calculator';
import CalendarEvents from './calendar-events';
import FooterGallery from './footer-gallery';
import Map from './map';
import OnlineOffers from '../../components/details/online-offers';
import IndicativePriceRange from '../../components/property-card/residential/indicative-price-range';
import CustomReporting from '../../components/details/CustomReporting';
import singleColOnly from '../../components/singleColOnly';
import { ResidentialCallAgentModal } from '../../components/details/call-agent-modal';
import FinxComponentsProvider from './home-loan/FinxComponentsProvider.js';
import LiveRampATS from '../../components/advert/liveramp-ats';
import { PrebidIframe } from '@media/ad-kit';
import startPrebid from '../../components/advert/startPrebid';
import { FeatureToggle } from '../../components/toggles';
import PropertySummaryBadge from '../../product-components/developer/property-summary';
import Inspections from '../../product-components/events/inspections/index.tsx';
import NbnSummary from '../../product-components/nbn-summary';
import NbnDetails from '../../product-components/nbn-details';

type Props = {
    data: BuyResidentialListingDetailsGetters,
    componentData: any,
    location: RouterLocation,
};

const fetchListingBookmarks = createFetchListingBookmarks();
const fetchEnquiryFormPrefillData = createFetchEnquiryFormPrefillData();
const removeEnquiryFormPrefillData = createRemoveEnquiryFormPrefillData();

class BuyResidentialListingDetails extends React.Component<Props> {
    _contactAgentEl: ?Element;

    _firstDocDownloadInputEl: ?HTMLElement;

    _docDownloadEl: ?Element;

    _inspectionTimesEl: ?Element;

    contactAgentEl = () => this._contactAgentEl;

    setContactAgentEl = (el: ?Element) => {
        this._contactAgentEl = el;
    };

    firstDocDownloadInputEl = () => this._firstDocDownloadInputEl;

    setFirstDocDownloadInputEl = (el: ?HTMLElement) => {
        this._firstDocDownloadInputEl = el;
    };

    docDownloadEl = () => this._docDownloadEl;

    setDocDownloadEl = (el: ?Element) => {
        this._docDownloadEl = el;
    };

    inspectionTimesEl = () => this._inspectionTimesEl;

    setInspectionTimesEl = (el: ?Element) => {
        this._inspectionTimesEl = el;
    };

    renderPropertyInfo() {
        const { data, componentData } = this.props;

        return (
            <PropertyInfo
                data={data}
                actionButtons={
                    <Fragment>
                        <ShareListing data={data} className="property-info__action" />
                        <BookmarkStar
                            className="listing-bookmark--details property-info__action"
                            data={data}
                            inPageSource="hero section"
                        />
                    </Fragment>
                }
                additionalContent={
                    <Stack gap="extraSmall">
                        <IndicativePriceRange data={data} showTooltip={false} allowFallbackPdf={false} />
                        <StatementOfInformation data={data} />
                    </Stack>
                }
                footerContent={
                    <Fragment>
                        <InspectionsSummary data={data} inspectionTimesEl={this.inspectionTimesEl} />
                        <AuctionDate data={data} />
                        <OnlineOffers data={data} />
                    </Fragment>
                }
                propertySummaryBadge={<PropertySummaryBadge data={componentData} />}
            />
        );
    }

    renderTrafficDriver = () => {
        const { data } = this.props;
        return (
            <Fragment>
                <EnquiryTrafficDriverMainImage data={data} />
                <SidebarTrafficDriver
                    data={data}
                    className="project-listing-company-context__detail-info"
                    cidFrom="buy:pdp:contact-form"
                />
            </Fragment>
        );
    };

    renderNbnDetailsComponent = (componentData: any) => (onClose: () => void) => {
        return <NbnDetails data={componentData} onClose={onClose} />;
    };

    renderContent = () => {
        const { data, location, componentData } = this.props;

        return (
            <div className="content" role="presentation">
                <FinxComponentsProvider data={data}>
                    <IntroductionMessage data={data} />
                    <DividedContent>
                        <ContextualPropertyInfo data={data} propertyInfo={this.renderPropertyInfo()} />
                        <HomeLoanCalculatorLauncher data={data} />
                        <RenderBelowTheFold data={data} placeholder={<FullWidthPlaceholder />}>
                            <Map
                                inspectionTimesEl={this.inspectionTimesEl}
                                setInspectionTimesEl={this.setInspectionTimesEl}
                                data={data}
                                inspectionsEl={
                                    <Inspections data={componentData} contactAgentEl={this.contactAgentEl} />
                                }
                                location={location}
                                contactAgentEl={this.contactAgentEl}
                            />
                            <TravelTime data={data} />
                            <Description data={data} />
                            <NbnSummary
                                data={componentData}
                                renderNbnDetailsComponent={this.renderNbnDetailsComponent(componentData)}
                            />
                            <FeatureToggle toggleName={'propertyFeature'}>
                                {(isPropertyFeatureToggledOn) =>
                                    isPropertyFeatureToggledOn ? (
                                        <PersonalisedPropertyFeatures
                                            data={componentData}
                                        ></PersonalisedPropertyFeatures>
                                    ) : (
                                        <PropertyFeatures data={data} />
                                    )
                                }
                            </FeatureToggle>
                            <div>
                                <ChildListingParentProjectBanner data={data} />
                                <ChildListingDocDownload
                                    data={data}
                                    setDocDownloadEl={this.setDocDownloadEl}
                                    setFirstDocDownloadInputEl={this.setFirstDocDownloadInputEl}
                                    firstDocDownloadInputEl={this.firstDocDownloadInputEl}
                                />
                            </div>
                            <FloorplansTours data={data} />
                            <FeatureToggle toggleName={'newEvents'}>
                                {(isEventsToggleOn) =>
                                    isEventsToggleOn ? (
                                        <Inspections data={componentData} contactAgentEl={this.contactAgentEl} />
                                    ) : (
                                        <CalendarEvents
                                            data={data}
                                            className=""
                                            contactAgentEl={this.contactAgentEl}
                                            setInspectionTimesEl={this.setInspectionTimesEl}
                                            inspectionTimesEl={this.inspectionTimesEl}
                                        />
                                    )
                                }
                            </FeatureToggle>
                            <HighlightVideo data={data} />
                            <HomeLoanCalculator data={data} />
                            <RenovationCalculator data={data} page="rea:buy:property details" />
                            <DetailsMarketInsights
                                data={data}
                                sourcePage="rea:buy:pdp"
                                sourceElement="suburb-profile"
                            />
                            <Schools data={data} />
                            <IsChildListing data={data} yes={null} no={<DividedStatementOfInformation data={data} />} />
                            <RecentSales data={data} />
                            <Faq data={data} />
                        </RenderBelowTheFold>
                    </DividedContent>
                </FinxComponentsProvider>
            </div>
        );
    };

    renderCallAgentModal = (data: CallAgentModalResidentialListing) => (isOpen: boolean, close: () => void) => {
        return <ResidentialCallAgentModal data={data} isOpen={isOpen} close={close} />;
    };

    renderMediaOverviewComponent = (componentData: any) => (onClose: () => void) => {
        return <MediaOverview data={componentData} onClose={onClose} />;
    };

    render() {
        const { data, location, componentData } = this.props;
        const ActionPanelSingleColOnly = singleColOnly(ActionPanel);
        if (data && data.getTrackingData()) {
            startPrebid(data, true);
        }

        return (
            <PageTracker data={data} location={location}>
                <CustomReporting data={data} />
                <PrebidIframe locationSearch={location.search} />
                <PersonalisedData
                    data={data}
                    location={location}
                    fetchOnSignedIn={[fetchListingBookmarks, fetchEnquiryFormPrefillData]}
                    removeOnNotSignedIn={[removeEnquiryFormPrefillData]}
                />
                <LiveRampATS location={location} />
                <ConsumerRequestsProvider>
                    <DetailsLayout
                        adsData={data}
                        subnav={<NavSecondary data={data} />}
                        branding={
                            <IsChildListing
                                data={data}
                                yes={<HeaderBrandingForChildListing data={data} />}
                                no={<HeaderBranding data={data} />}
                            />
                        }
                        hero={
                            <Fragment>
                                <Hero
                                    data={data}
                                    pip={this.renderPropertyInfo()}
                                    badgeContainer={<StatusBadge data={data} />}
                                    renderMediaOverview={this.renderMediaOverviewComponent(componentData)}
                                />
                                <MediaViewerForDetails data={data} contactAgentEl={this.contactAgentEl} />
                            </Fragment>
                        }
                        sidebarPrimary={
                            <ContactAgentPanel
                                data={data}
                                BrandingComponent={ContactAgentBranding}
                                SidebarTrafficDriverComponent={SidebarTrafficDriver}
                                buttons={
                                    <Stack gap="medium">
                                        <ScrollableGetInTouchButton
                                            viewEmailAgentTracker={() =>
                                                data.getEventTracker().viewEmailAgentForm('contact side panel')
                                            }
                                            contactAgentEl={this.contactAgentEl}
                                            className="contact-agent-panel__button"
                                        />
                                        <BookmarkButton data={data} inPageSource="contact side panel" />
                                    </Stack>
                                }
                                footer={
                                    <RenderBelowTheFold data={data}>
                                        <ChildListingRequestProjectInfoKitPanel
                                            data={data}
                                            docDownloadEl={this.docDownloadEl}
                                            firstDocDownloadInputEl={this.firstDocDownloadInputEl}
                                        />
                                    </RenderBelowTheFold>
                                }
                            />
                        }
                        sidebarSecondary={[
                            <RenderBelowTheFold data={data} key="buy_DetailsMedRec1Ad">
                                <DetailsMedRec1Ad data={data} className="advert--centered" />
                            </RenderBelowTheFold>,
                            <RenderBelowTheFold data={data} key="buy_DetailsMedRec2Ad">
                                <WidgetAd data={data} pos={1} />
                                <DetailsMedRec2Ad data={data} className="advert--centered" />
                            </RenderBelowTheFold>,
                        ]}
                        content={this.renderContent()}
                        footer={
                            <RenderBelowTheFold data={data} placeholder={<ContentLayoutPlaceholder />}>
                                <ActionPanelSingleColOnly
                                    data={componentData}
                                    contactAgentEl={this.contactAgentEl}
                                    renderCallAgentModal={this.renderCallAgentModal(data)}
                                    renderApplyOnlineButton={() => null}
                                />
                                <ContactAgentLayout
                                    data={data}
                                    listingType="residential"
                                    setContactAgentEl={this.setContactAgentEl}
                                    renderBranding={(brandingProps) => (
                                        <RenderAgencyBrandingIfRequired
                                            data={data}
                                            render={() => <ContactAgentBranding data={data} {...brandingProps} />}
                                        />
                                    )}
                                    contactAgentForm={
                                        <ContactAgentForm data={data} renderTrafficDriver={this.renderTrafficDriver} />
                                    }
                                    listingMetrics={<ListingMetrics data={data} showPageViews={true} />}
                                    sidebar={
                                        <Fragment>
                                            <SingleOrDoubleColumn>
                                                {({ isDoubleCol }) =>
                                                    isDoubleCol && (
                                                        <ProjectOrListingCompanyContext
                                                            data={data}
                                                            renderTrafficDriver={this.renderTrafficDriver}
                                                        />
                                                    )
                                                }
                                            </SingleOrDoubleColumn>
                                            <DetailsMedRec3Ad data={data} className="advert--centered" />
                                        </Fragment>
                                    }
                                />
                                <FooterGallery data={data} key={location.pathname + location.search} />
                                <div className="layout" role="presentation">
                                    <PriceDisclaimer data={data} />
                                </div>
                                <FloorMedRecAdvert data={data} />
                            </RenderBelowTheFold>
                        }
                    />
                </ConsumerRequestsProvider>
            </PageTracker>
        );
    }
}

export default BuyResidentialListingDetails;
