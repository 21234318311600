/* @flow */
import React from 'react';
import Item from './item';
import './styles.scss';
import type { GetSoldResidentialListing } from '../../models/lexa';
import SectionHeading from '../details/section-heading';
import SoldPropertyDataDisclaimer from '../results/sold-property-data-disclaimer';

type Props = {|
    title: string,
    items: Array<GetSoldResidentialListing>,
|};

const View = ({ items, title }: Props) => {
    return (
        <div className="recent-sales">
            <SectionHeading>{title}</SectionHeading>
            <div>
                {items.map((item, index) => (
                    <Item key={index} data={item} />
                ))}
            </div>
            <SoldPropertyDataDisclaimer />
        </div>
    );
};

export default View;
