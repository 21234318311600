/* @flow */
import React from 'react';
import type { ViewProps } from './types';
import { SiblingsCarousel } from '../../../components/details/footer-carousel';
import './styles.scss';
import { TemplatedLazyImage } from '../../../components/Image';
import { ResponsiveLinkButton } from '../../../components/buttons';
import { Strong } from '@rea-group/construct-kit-core';
export default (props: ViewProps) => {
    const { name, title, description, projectLink, backgroundColor, templatedUrl, numOfChildListings, data } = props;
    return (
        <section className="project-footer">
            <div className="project-footer__branding" style={{ backgroundColor }}>
                <TemplatedLazyImage
                    templatedUrl={templatedUrl}
                    size="160x30"
                    avif={true}
                    className="project-footer__logo"
                    alt=""
                />
            </div>
            <div className="project-footer__content">
                <div className="project-footer__name">{name}</div>
                <div className="project-footer__title">{title}</div>
                <div dangerouslySetInnerHTML={{ __html: description }} className="project-footer__description" />
                <div className="project-footer__explore-more-text">
                    Explore
                    {numOfChildListings ? <Strong>{` ${numOfChildListings} `}</Strong> : ' '}
                    properties in {name}
                </div>
                <ResponsiveLinkButton href={projectLink}>View more properties</ResponsiveLinkButton>
            </div>
            <SiblingsCarousel data={data} />
        </section>
    );
};
