/* @flow */
import React, { useContext } from 'react';
import styled from 'styled-components';
import ErrorBoundaryNewRelicReporter from '../../../../components/error-boundary-new-relic-reporter';
import BrowserOnly from '../../../../components/browser-only';
import { scrollTo } from '../../../../util/DOMUtils';
import StickyBrandingContext from '../../../../components/details/StickyBrandingContext.ts';
import { mediaQueryHelper, Skeleton } from '@rea-group/construct-kit-core';

const LazyFinxPdpCalculatorLauncher = React.lazy(
    () => import(/* webpackChunkName: "home-loan-calculator-uplift" */ './FinxLoanCalculatorLauncherLazy')
);

const Placeholder = styled(Skeleton).attrs({ height: '4.5rem', borderRadius: 'medium' })`
    ${mediaQueryHelper('medium')} {
        height: 3.25rem;
    }
`;

const scrollToCalculator = (offset: number) => {
    const element = document.querySelector('#calculator');
    element && scrollTo(element, offset);
};

const NewLauncher = () => {
    const { stickyBrandingOffset } = useContext(StickyBrandingContext);
    return (
        <BrowserOnly name="FinxCalculatorLauncher" fallback={<Placeholder />}>
            <LazyFinxPdpCalculatorLauncher
                onCalculateClickForLargeScreen={() => scrollToCalculator(stickyBrandingOffset)}
            />
        </BrowserOnly>
    );
};

const Launcher = () => {
    return (
        <ErrorBoundaryNewRelicReporter component="FinxCalculator">
            <NewLauncher />
        </ErrorBoundaryNewRelicReporter>
    );
};
export default Launcher;
