/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import type { GetBuyResidentialListing, BuyResidentialListing } from '../../../models/lexa';
import type { Price } from '../../../models/lexa/priceType';
import getFragmentData from '../../../util/getFragmentData';
import PriceDisclaimer from './PriceDisclaimer.graphql';

type Props = {
    price: Price,
};

export type AdapterProps = {
    disclaimer?: ?string,
};

const dataAdapter = ({ price }: Props): AdapterProps => ({
    disclaimer: price && price.disclaimer ? price.disclaimer : null,
});

export default compose<GetBuyResidentialListing, BuyResidentialListing, Props, AdapterProps>(
    dataAdapter,
    getFragmentData(PriceDisclaimer),
    (data) => data.getListing()
);
