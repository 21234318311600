/* @flow */

import React, { useContext } from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { BuyResidentialListingDetailsGetters } from '../../../../models/lexa';
import ChannelContext from '../../../../util/ChannelContext';

type Props = {
    contactAgentEl: () => ?Element,
    data: BuyResidentialListingDetailsGetters,
};

const Auction = ({ data, contactAgentEl }: Props) => {
    const channel = useContext(ChannelContext);
    const viewEmailAgentTracker = () => data.getEventTracker().viewEmailAgentForm('contact form');
    const viewOnlineAuctionTracker = (auctionUrl) => {
        const provider = new URL(auctionUrl).hostname;
        provider && data.getEventTracker().viewOnlineAuction(provider);
    };
    const adaptedData = adaptLexaData(channel)(data);

    return adaptedData ? (
        <View
            saveToCalendarProps={adaptedData}
            contactAgentEl={contactAgentEl}
            viewEmailAgentTracker={viewEmailAgentTracker}
            viewOnlineAuctionTracker={viewOnlineAuctionTracker}
            channel={channel}
        />
    ) : null;
};

export default Auction;
