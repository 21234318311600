/* @flow */
import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import type {
    GetResidentialListing,
    ResidentialListing,
    Address,
    Price,
    LexaPropertyType,
    GeneralFeatures,
    ResidentialListingViewConfiguration,
} from '../../../../models/lexa';
import CalculatorPropertyDetails from './CalculatorPropertyDetails.graphql';

const AbbreviatedStates = {
    ACT: 'ACT',
    NSW: 'NSW',
    NT: 'NT',
    QLD: 'QLD',
    SA: 'SA',
    TAS: 'TAS',
    VIC: 'VIC',
    WA: 'WA',
};

type PropertyDetails = {
    listingId: string,
    suburb: string,
    address: ?string,
    propertyType: LexaPropertyType,
    postcode: string,
    state: string,
    bedrooms: number | 'ALL',
};

export type AdaptedData = {
    propertyDetails: PropertyDetails,
    showHomeLoanCalculator: boolean,
};

type RequiredProps = {
    id: string,
    price: Price,
    propertyType: LexaPropertyType,
    generalFeatures: ?GeneralFeatures,
    address: Address,
    viewConfiguration: ResidentialListingViewConfiguration,
};

const dataAdapter = ({
    id,
    price,
    propertyType,
    generalFeatures,
    address,
    viewConfiguration,
}: RequiredProps): AdaptedData => {
    const listingId = id;
    const { postcode, state, suburb, display } = address;
    const bedrooms = generalFeatures ? generalFeatures.bedrooms.value : 0;
    const safeState = state && state.toUpperCase() in AbbreviatedStates ? state.toUpperCase() : AbbreviatedStates.NSW;

    const { homeLoanCalculator } = viewConfiguration.details;

    return {
        propertyDetails: {
            listingId,
            suburb,
            address: display.shortAddress,
            propertyType,
            postcode,
            state,
            bedrooms,
        },
        listingDetails: {
            listingId,
            listedPriceDescription: price.display,
            indicativePriceDescription: price.information?.formattedDisplay,
            suburb,
            propertyTypeDisplay: propertyType.display,
            postcode,
            state: safeState,
            bedrooms,
        },
        address: {
            shortAddress: display.shortAddress,
            suburb,
        },
        showHomeLoanCalculator: homeLoanCalculator,
    };
};

const adaptLexaData = compose<GetResidentialListing, ResidentialListing, RequiredProps, AdaptedData>(
    dataAdapter,
    getFragmentData(CalculatorPropertyDetails),
    (data) => data.getListing()
);

export default adaptLexaData;
