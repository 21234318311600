/* @flow */
import React from 'react';
import RelatedProperties from '../../../components/related-properties';
import BuilderProfileFooter from '../builder-profile-footer';
import ProjectFooter from '../project-footer';
import adaptLexaData from './adaptLexaData';
import type { GetBuyResidentialListing, GetRelatedProperties } from '../../../models/lexa';

const getListingData = (data) => adaptLexaData(data);

export const View = ({ data }: { data: GetBuyResidentialListing & GetRelatedProperties }) => {
    const { showProjectFooter, showHomeDesignFooter, showRelatedPropertiesFooter } = getListingData(data);
    if (showProjectFooter) {
        return <ProjectFooter data={data} />;
    }
    if (showHomeDesignFooter) {
        return <BuilderProfileFooter data={data} />;
    }
    if (showRelatedPropertiesFooter) {
        return <RelatedProperties data={data} />;
    }

    return null;
};
