import React from 'react';
import styled, { css } from 'styled-components';
import { paletteHelper, spacingHelper, GavelLg, Inline } from '@rea-group/construct-kit-core';
import type { ViewProps } from './types';

const AuctionDetails = styled.div`
    display: flex;
    align-items: center;
`;

type IconContainerProps = {
    $isLive: boolean;
};

const IconContainer = styled.span<IconContainerProps>`
    display: inline-flex;
    align-items: center;
    border-radius: ${spacingHelper('twoExtraSmall')};
    background: ${paletteHelper('backgroundBrandStatic')};
    color: ${paletteHelper('textInvertedStatic')};
    margin-right: ${spacingHelper('extraSmall')};
    padding: 0.125rem;

    ${({ $isLive }) =>
        $isLive &&
        css`
            padding-right: ${spacingHelper('twoExtraSmall')};
        `}
`;

const FilledGavel = styled(GavelLg)`
    color: transparent;
    height: 1rem;
    width: 1rem;

    & > path {
        fill: ${paletteHelper('textInvertedStatic')};
    }
`;

const StyledAuctionTime = styled.span`
    display: none;
    ${({ theme }) => theme.breakpoints.medium} {
        display: block;
    }
`;

export default ({ auctionDate, auctionTime, hasOnlineAuction, isLive }: ViewProps) => {
    if (!auctionDate) {
        return null;
    }

    const isLiveOnlineAuction = hasOnlineAuction && isLive;
    return (
        <AuctionDetails>
            <IconContainer $isLive={isLiveOnlineAuction}>
                <FilledGavel />
                {isLiveOnlineAuction ? 'Live' : null}
            </IconContainer>
            <Inline gap="twoExtraSmall">
                <span role="text">{auctionDate}</span>
                {auctionTime && <StyledAuctionTime role="text">{auctionTime}</StyledAuctionTime>}
            </Inline>
        </AuctionDetails>
    );
};
