/* @flow */
import { adaptOnlineAuctionIndicator } from '../../auction-date';
import type { GetBuyResidentialListing } from '../../../models/lexa';
import type { ViewProps } from '../../auction-date/types';

type DataType = { data: GetBuyResidentialListing };

export default ({ data }: DataType): ViewProps => {
    const listing = data.getListing();
    const onlineAuctionIndicatorData = adaptOnlineAuctionIndicator(listing);

    const auctionDate = listing.auction && `Auction ${listing.auction.dateTime.display.longLabel}`;
    return {
        auctionDate,
        ...onlineAuctionIndicatorData,
    };
};
