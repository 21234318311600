/* @flow */
import getFragmentData from '../../util/getFragmentData';
import { compose3 as compose } from '../../util/functional/compose';
import type { GetBuyResidentialListing, BuyResidentialListing } from '../../models/lexa';
import IsChildListing from './IsChildListing.graphql';

type AdaptedProps = {
    isChildListing: boolean,
};

const adapter = (data) => {
    return { isChildListing: data.parent ? true : false };
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, BuyResidentialListing, AdaptedProps>(
    adapter,
    getFragmentData(IsChildListing),
    (data) => data.getListing()
);
