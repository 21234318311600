/* @flow */
import React from 'react';
import CalendarEvent from '../calendar-event/View';
import './styles.scss';
import ScrollToElement from '../../ScrollToElement';
import type { SaveToCalendarProps } from './adaptLexaData';
import styled from 'styled-components';
import { Link, GavelLg, paletteHelper, spacingHelper, Button } from '@rea-group/construct-kit-core';
import SectionHeading from '../../section-heading';
import { createBrowserUser } from '@realestate.com.au/user';
import type { Channel } from '../../../../models/Channel';

const FilledGavel = styled(GavelLg)`
    color: transparent;
    & > path {
        fill: ${paletteHelper('textInvertedStatic')};
    }
`;

const OnlineAuctionLink = styled(Link).attrs({ variant: 'button-primary', fullWidth: true })`
    margin-top: ${spacingHelper('medium')};

    ${({ theme }) => theme.breakpoints.large} {
        width: fit-content;
        margin-top: 0;
    }
`;

const CalendarEventWrapper = styled.div`
    padding: ${spacingHelper('medium 0')};
    border-bottom: 1px solid ${paletteHelper('borderSecondary')};
    &:first-child {
        padding-top: 0;
    }
`;

const AuctionContainerWrapper = styled.div`
    padding: ${spacingHelper('medium medium 0 medium')};
    margin-top: ${spacingHelper('medium')};
    border: 1px solid;
    border-color: ${paletteHelper('borderSecondary')};
    border-radius: ${spacingHelper('small')};
`;

type Props = {
    saveToCalendarProps: SaveToCalendarProps,
    contactAgentEl: () => ?Element,
    viewEmailAgentTracker: () => void,
    viewOnlineAuctionTracker: (string) => void,
    channel: Channel,
};

const ContactAgentLink = ({ onClick }) => (
    <Button variant="link-inline" onClick={onClick}>
        Contact the agent
    </Button>
);

const ContactAgent = ({ contactAgentEl, viewEmailAgentTracker }) => (
    <p className="auction__contact-agent">
        <ScrollToElement elementRef={contactAgentEl} afterScroll={viewEmailAgentTracker}>
            {(scrollOnClick) => <ContactAgentLink onClick={scrollOnClick} />}
        </ScrollToElement>
        {` for auction details.`}
    </p>
);

const OnlineAuction = ({ auctionUrl, onlineAuctionDescription, onlineAuctionCtaDisplay, viewOnlineAuctionTracker }) => (
    <p className="auction__online-auction">
        <span>{onlineAuctionDescription}</span>
        <OnlineAuctionLink
            href={auctionUrl}
            icon={<FilledGavel />}
            onClick={() => viewOnlineAuctionTracker(auctionUrl)}
            target={'_blank'}
            rel="noopener nofollow"
        >
            {onlineAuctionCtaDisplay}
        </OnlineAuctionLink>
    </p>
);

const Auction = ({
    saveToCalendarProps,
    contactAgentEl,
    viewEmailAgentTracker,
    viewOnlineAuctionTracker,
    channel,
}: Props) => {
    const {
        auctionUrl,
        onlineAuctionCtaDisplay,
        onlineAuctionDescription,
        eventTrackers,
        address,
        event,
        trackAddToPlan,
        listingId,
        templatedUrl,
        registrationEventTrackers,
    } = saveToCalendarProps;

    return (
        <div className="auction">
            <SectionHeading>Auction</SectionHeading>
            <AuctionContainerWrapper className="auction__time-row">
                <CalendarEventWrapper>
                    <CalendarEvent
                        {...{
                            event,
                            address,
                            eventTrackers,
                            channel,
                            listingId,
                            templatedUrl,
                            trackAddToPlan,
                            registrationEventTrackers,
                        }}
                        getUser={createBrowserUser}
                        shouldShowRegistrationButton={false}
                    />
                </CalendarEventWrapper>
                {auctionUrl ? (
                    <OnlineAuction
                        auctionUrl={auctionUrl}
                        onlineAuctionDescription={onlineAuctionDescription}
                        onlineAuctionCtaDisplay={onlineAuctionCtaDisplay}
                        viewOnlineAuctionTracker={viewOnlineAuctionTracker}
                    />
                ) : (
                    <ContactAgent contactAgentEl={contactAgentEl} viewEmailAgentTracker={viewEmailAgentTracker} />
                )}
            </AuctionContainerWrapper>
        </div>
    );
};

export default Auction;
