/* @flow */

import React, { type Element } from 'react';
import type { GetBuyResidentialListing } from '../../models/lexa';
import IsChildListing from '../../components/is-child-listing';
import IsBuilderListing from '../../components/is-builder-listing';

type Props = {
    data: GetBuyResidentialListing,
    renderAgencyListing: () => ?Element<*>,
    renderBuilderListing: () => ?Element<*>,
    renderChildListing: () => ?Element<*>,
};

const ListingTypeSwitch = (props: Props) => {
    const { data, renderChildListing, renderBuilderListing, renderAgencyListing } = props;
    return (
        <IsChildListing
            data={data}
            yes={renderChildListing()}
            no={<IsBuilderListing data={data} yes={renderBuilderListing()} no={renderAgencyListing()} />}
        />
    );
};

export default ListingTypeSwitch;
