/* @flow */
import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import IndicativePriceRange from './IndicativePriceRange.graphql';
import type { GetBuyResidentialListing, BuyResidentialListing } from '../../../../models/lexa';
import type { IndicativePriceRangeInformation } from '../../../../models/lexa/priceType';

const dataAdapter = (listing: BuyResidentialListing): ?IndicativePriceRangeInformation => {
    if (!listing.price.information) return null;

    return {
        tooltipText: listing.price.information.tooltipText,
        formattedDisplay: listing.price.information.formattedDisplay,
        fallbackPdf: listing.price.information.fallbackPdf,
    };
};

const adapter = compose<
    GetBuyResidentialListing,
    BuyResidentialListing,
    BuyResidentialListing,
    ?IndicativePriceRangeInformation,
>(dataAdapter, getFragmentData(IndicativePriceRange), (data) => data.getListing());

export default adapter;
