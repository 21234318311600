/* @flow */
import React, { useState } from 'react';
import { Badge, Tooltip, P, Inline, Link, ExternalLinkMd } from '@rea-group/construct-kit-core';
import { parseLexaToHTML } from '../../../../util/string/parseLexaToHTML';
import type { IndicativePriceRangeInformation } from '../../../../models/lexa';
import type { DisclaimerInteractionEvent } from '../../../../client/tracking/event/types';
import styled from 'styled-components';

type Props = {
    information: ?IndicativePriceRangeInformation,
    showTooltip: boolean,
    allowFallbackPdf: boolean,
    disclaimerInteractionEvent: (event: DisclaimerInteractionEvent) => void,
    statementOfInformationLinkClick: () => void,
};

const StyledLinkWrapper = styled.div`
    position: relative;
    z-index: 9;
    // This is needed due to the global a:hover selector in src/styles/typography.scss
    // Preferably we would get rid of that global selector, but is impossible to tell what is needing it currently
    @media only screen and (hover: none) {
        * > a {
            text-decoration: underline;
        }
    }
`;

const View = ({
    information,
    showTooltip,
    allowFallbackPdf,
    disclaimerInteractionEvent,
    statementOfInformationLinkClick,
}: Props) => {
    const [isTracked, setIsTracked] = useState(false);

    const onOpen = (peek) => {
        !isTracked &&
            disclaimerInteractionEvent({
                in_page_source: 'indicative price guide disclaimer',
                user_action: peek ? 'hover' : 'tap',
            });

        setIsTracked(true);
    };

    if (!information) return null;

    return (
        <React.Fragment>
            {!!information.formattedDisplay && (
                <Inline grow={false} gap={'0'}>
                    <Badge variant="neutral">
                        <P variant="caption">{parseLexaToHTML(information.formattedDisplay)}</P>
                    </Badge>
                    {showTooltip && (
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Tooltip buttonLabel="More information" onOpen={onOpen}>
                                {information.tooltipText}
                            </Tooltip>
                        </div>
                    )}
                </Inline>
            )}
            {!information.formattedDisplay && allowFallbackPdf && (
                <StyledLinkWrapper
                    onClick={(event) => {
                        statementOfInformationLinkClick();
                        event.stopPropagation();
                    }}
                >
                    <P variant="body02">
                        <Link
                            target="_blank"
                            className="price-guide-fallback"
                            rel="noopener noreferrer"
                            sizeVariant="small"
                            variant="secondary"
                            icon={<ExternalLinkMd aria-label="open indicative price in a new window" />}
                            iconPlacement="right"
                            href={information.fallbackPdf.href}
                        >
                            View indicative price
                        </Link>
                    </P>
                </StyledLinkWrapper>
            )}
        </React.Fragment>
    );
};

export default View;
