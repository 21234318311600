/* @flow */
import React from 'react';
import { spacingHelper, Text, paletteHelper, Link } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import propTrackLogo from './proptrack-logo.svg';
import { WIDTH_CONSTRAIN } from '../../../../styles/application/layout';

const PropTrackLogo = styled.img`
    width: 87px;
    height: 28px;
`;

const Container = styled(Text).attrs({ forwardedAs: 'div', variant: 'body02' })`
    margin: ${spacingHelper('large')} ${spacingHelper('medium')} 0 ${spacingHelper('medium')};
    @media (min-width: ${WIDTH_CONSTRAIN + 1}px) {
        margin: ${spacingHelper('large')} 0 0 0;
    }
    .recent-sales & {
        margin: ${spacingHelper('large')} 0 0 0;
    }
`;

const StyledText = styled(Text).attrs({ variant: 'body02' })`
    color: ${paletteHelper('textSecondary')};
    margin: ${spacingHelper('small')} 0 0 0;
`;

const SoldPropertyDataDisclaimer = () => {
    return (
        <Container>
            <PropTrackLogo src={propTrackLogo} alt="prop-track-logo" />
            <StyledText>
                This information is supplied by Realestate.com.au (ABN 21 080 195 535) on behalf of Proptrack Pty Ltd
                (ABN 43 127 386 298).&nbsp;
                <Link target="_blank" href="https://about.realestate.com.au/pricing-data-usage-disclaimers">
                    Copyright and Legal Disclaimers about Property Data
                </Link>
            </StyledText>
        </Container>
    );
};
export default SoldPropertyDataDisclaimer;
