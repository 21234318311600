// @flow
import React from 'react';
import { Provider } from 'react-redux';
import '../../../styles/main.scss';
import { renderAction, notFoundAction, permanentRedirectAction, temporaryRedirectAction } from '@rea-argonaut/actions';
import View from './View';
import { getBuyPropertyDetailsGetters } from '../PropertyDetailsGetters';
import createLocationFromHistoryLocation from '../../util/createLocationFromHistoryLocation';
import { ResetNavigationState } from '../../components/navigation';
import { type BuyResidentialListing } from '../../models/lexa';
import getBuySchemaMarkup from '../../components/details/meta-data/residential/getSchemaMarkup';
import { REISSUE_TOKEN_ENDPOINT } from '../../config';
import { fetchDetailsData, fetchDetailsFull } from './fetchData';
import { getTestParams, UpdatePartialDataToFullData } from '../details';
import ProviderWrapper, { getActionDecorator } from '../../providers';
import getHead from '../details/residential/getHead';
import createSsrExchange from '../../urql/ssrExchange';
import persistedCreateClient from '../../urql/client';
import writeUrqlCacheToExchange from '../../urql/writeUrqlCacheToExchange';
import ResidentialDetailsPreHeader from '../details/residential/ResidentialDetailsPreHeader';
import { isTokenExpired } from './isTokenExpired';
import { refreshView } from '@media/ad-kit';
import { getComponentGetters } from '../../product-components/ComponentDetailsGetters';

export const getAction = getActionDecorator<{| listingId: string |}>(
    ({ store, isInitialRender }) =>
        async ({ url, pathParams, user }) => {
            const ssrExchange = await createSsrExchange();
            const client = persistedCreateClient({ ssrExchange });
            const queryVariables = { id: pathParams.listingId, ...getTestParams(url) };
            const detailsData = await fetchDetailsData({
                client,
                queryVariables,
                isInitialRender,
            });

            if (isTokenExpired(detailsData)) {
                return temporaryRedirectAction(
                    `${REISSUE_TOKEN_ENDPOINT}?continueUrl=${encodeURIComponent(url.toString())}`
                );
            }

            if (!detailsData) {
                return notFoundAction();
            }

            if (detailsData.value.details.__typename === 'SoldResidentialListingDetails') {
                return permanentRedirectAction(detailsData.value.details.listing._links.canonical.path);
            }

            if (detailsData.value.details.__typename !== 'BuyResidentialListingDetails') {
                return notFoundAction();
            }

            const listing: BuyResidentialListing = detailsData.value.details.listing;

            await writeUrqlCacheToExchange({ ssrExchange });

            const location = createLocationFromHistoryLocation({
                search: url.search,
                pathname: url.pathname,
            });

            refreshView();

            return renderAction(async () => ({
                getElements: async () => ({
                    Screen: (
                        <ProviderWrapper channel="buy" store={store} user={user}>
                            <ResetNavigationState>
                                <UpdatePartialDataToFullData
                                    data={detailsData}
                                    fetchDetailsFull={() =>
                                        fetchDetailsFull({
                                            client,
                                            queryVariables,
                                        })
                                    }
                                >
                                    {(data) => (
                                        <View
                                            data={getBuyPropertyDetailsGetters({ data })}
                                            componentData={getComponentGetters({ data })}
                                            location={location}
                                        />
                                    )}
                                </UpdatePartialDataToFullData>
                            </ResetNavigationState>
                        </ProviderWrapper>
                    ),
                    preHeader: (
                        <Provider store={store}>
                            <ResidentialDetailsPreHeader
                                getGetters={getBuyPropertyDetailsGetters}
                                initialData={detailsData}
                            />
                        </Provider>
                    ),
                }),
                getHead: async () =>
                    getHead<BuyResidentialListing>({
                        listing,
                        channel: 'buy',
                        hostHeader: url.host,
                        getSchemaMarkup: getBuySchemaMarkup,
                    }),
            }));
        }
);
